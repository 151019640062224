angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.configuration_account', {
            url: '/configuration/account',
            views: {
                "main": {
                    controller: 'AccountCtrl',
                    templateUrl: 'web/main/configuration/account/account.tpl.html',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                user: function (session, userService) {
                    return userService.getUsuarios();
                }
            },

            data: {
                pageTitle: 'Account'
            }
        });
    })

    .controller('AccountCtrl', function($scope, userService, $window, changeSignatureModal,  $timeout, $rootScope, session, fingerinkSession, changePasswordModal) {

        var that = this;

        that.user = session.get().user;
        that.initials =   (that.user.name + (that.user.surname?' '+that.user.surname:'')).split(' ').map(a => a[0]).reduce((a, b) => a + b);

        that.checkMobile = $window.innerWidth < 768;


        userService.singImageURL().then(function (response) {
            that.userFirmaImage = response.data;
        });

        that.verFirma = function () {

            changeSignatureModal.openModal().result.then(function (data) {
                if (data) {
                    that.userFirmaImage = data;
                    $rootScope.$broadcast("signatureChanged", data);
                }
            });
        };




        $scope.$on('signatureChanged', function(data, signature){
            that.userFirmaImage = signature;
        });




        var initializingEmailNotifications = true;
        $scope.$watch('controller.user.emailNotifications', function () {
            if (initializingEmailNotifications) {
                $timeout(function () {
                    initializingEmailNotifications = false;
                });
                return;
            }
            var bool = that.user.emailNotifications;
            userService.getUsuarios(that.user).then(function (response) {
                that.user = response.data[0];
                that.user.emailNotifications = bool;
                userService.updateUsuario(that.user).then(function (response2) {
                    fingerinkSession.renoveToken();
                    if (bool) {
                        swal('Correcto!', 'Se ha activado el envío de notificaciones por correo', 'success');
                    } else {
                        swal('Correcto!', 'Se han desactivado el envío de notificaciones por correo', 'success');
                    }
                });
            });
        });



        var initializingNewsletter = true;
        $scope.$watch('controller.user.useNewsLetter', function () {
            if (initializingNewsletter) {
                $timeout(function () {
                    initializingNewsletter = false;
                });
                return;
            }
            var bool = that.user.useNewsLetter;
            userService.getUsuarios(that.user).then(function (response) {
                that.user = response.data[0];
                that.user.useNewsLetter = bool;
                userService.updateUsuario(that.user).then(function (response2) {
                    fingerinkSession.renoveToken();
                    if (bool) {
                        swal('Correcto!', 'Se ha activado el uso de tu correo para nuestro NewsLetter', 'success');
                    } else {
                        swal('Correcto!', 'Se ha desactivado el uso de tu correo para nuestro NewsLetter', 'success');
                    }
                });

            });

        });








        that.cambiarContrasenya = function () {
            changePasswordModal.openModal();
        };





    });
